import React from 'react';
import "./SubPage.css"
const Nosotros = () => {
  return (
    <div>
      <div className="image-overlay-container">
        <h1 className="overlay-title">Colegio Trupam</h1>
      </div>

      <div className="container-pages">
      {/* Columna Izquierda */}
      <div className="container-pages-left">
        <h2>Información general</h2>
      </div>

      {/* Columna Derecha */}
      <div className="container-pages-right">
        <div className="container-pages-description">
          <h3>Misión</h3>
          <p>
          Brindar calidad en el proceso de enseñanza-aprendizaje, a través del desarrollo de
          conocimiento, actitudes, habilidades y/o destrezas necesarias para la sociedad y tecnología
          actual y del futuro, enfatizando hábitos y valores propios de la Unidad Educativa, orientados a
          fortalecer su formación ética, crecimiento, autoafirmación personal e interacción con otros y
          con el mundo.
          </p>
          <br />
          <h3>Visión</h3>
          <p>
          Escuela Trupam se propone educar al alumno desarrollando de forma equilibrada las
          capacidades intelectuales, afectivas y físicas, favoreciendo la estimulación de las diferentes
          fortalezas que presenta cada estudiante potenciando en él su desarrollo integral basado en
          el respeto y difusión de principios y valores propios de la Escuela Trupamy del permanente
          ejercicio de identidad nacional en una sanaconvivencia democrática. Con la proyección de
          forjar alumnos que se inserten en un establecimiento de enseñanza media acorde a sus
          expectativas, sorteando las dificultades con éxito convirtiéndose en unciudadano de bien
          para nuestra sociedad.
          </p>
          {/* Botón */}
          <a
          href="/docs/PEI.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="container-pages-button"
          >
            Proyecto educativo institucional
          </a>
        </div>
        <div className="container-pages-details">
          <h4>Otros documentos de interés</h4>
          <p>

          Reglamento de evaluación:

          <br />
          <a
          href="/docs/Reglamento-Evaluacion.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="container-pages-button"
          > Descargar aquí</a>  
          </p>
          <br />
          <p>
            Reglamento de convivencia:
          <br />
          <a
          href="/docs/Reglamento-Convivencia.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="container-pages-button"
          > Descargar aquí</a>  
          </p>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Nosotros;
