import React from "react";
import { Link } from "react-router-dom";
import "./Home.css";
import logoColegio from '../../assets/images/logo-colegio.png'
import logoJardin from '../../assets/images/logo-jardin.png'

function Home() {
  return (
    <div className="home-container">
      <div className="icons-container">
        {/* Botón de acceso al Colegio */}
        <Link to="/colegio" className="icon">
          <img src={logoColegio} alt="Logo Colegio" className="home-logo" />
        </Link>

        {/* Botón de acceso al Jardín */}
        <Link to="/jardin" className="icon">
          <img src={logoJardin} alt="Logo Jardín" className="home-logo" />
        </Link>
      </div>
    </div>
  );
}

export default Home;
