import React, { useState } from "react";

const Slider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Función para ir a la siguiente imagen
  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 >= images.length ? 0 : prevIndex + 1
    );
  };

  // Función para ir a la imagen anterior
  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) =>
        prevIndex - 1 < 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="slider-container">
      <div className="slider">
        <img
          src={images[currentIndex]}
          alt={`Imagen del carrusel ${currentIndex}`}
          className="slider-image"
        />
      </div>
      
      {/* Botón para la imagen anterior */}
      <button className="slider-prev-btn" onClick={prevSlide}>
        &lt;
      </button>

      {/* Botón para la siguiente imagen */}
      <button className="slider-next-btn" onClick={nextSlide}>
        &gt;
      </button>
    </div>
  );
};

export default Slider;
