import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-column">
        <h3>Colegio Trupam</h3>
        <p>
          Educación de calidad
        </p>
      </div>
      <div className="footer-column">
        <h3>Contacto</h3>
        <ul>
          <li>Email: colegio@trupam.cl</li>
          <li>Teléfono:+56 2 26232404</li>
          <li>Dirección: Av. Cardenal José María Caro #1530, Conchalí</li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
