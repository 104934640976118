import React, { useState } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo-colegio.png';

const Header = () => {
  // Inicializa el estado en false para que el menú esté cerrado por defecto
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="Logo del colegio" />
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <nav className={`menu ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
        <ul>
          <li><Link to="/colegio">Inicio</Link></li>
          <li><Link to="/Colegio/admision">Admision</Link></li>
          <li><Link to="/Colegio/nosotros">Nosotros</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;