// src/pages/Postulacion.js
import React from 'react';
import "./SubPage.css"
import Form from "../../components/Form/Form";

const Certificados = () => {
  return (
    <div>
      <div className="image-overlay-container">
        <h1 className="overlay-title">Solicitud de certificados</h1>
      </div>

      <div className="container-pages">
      {/* Columna Izquierda */}
      <div className="container-pages-left">
        <h2 className='overlay-title2'>Instrucciones</h2>
        <p>1° Realizar pago de $500 por certificado
          <br></br>
          2° Completar formulario de solicitud
          <br></br>
          3° La entrega se realiza en un máximo de 2 días hábiles
        </p>
      </div>

      {/* Columna Derecha */}
      <div className="container-pages-right">
        <div className="container-pages-description">
          <Form />
        </div>

      </div>
    </div>
    </div>
  );
}
export default Certificados;
