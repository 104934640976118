import React from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo-jardin.png';

const HeaderJardin = () => {
  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="Logo del colegio" />
      </div>
      <nav className="menu">
        <ul>
        <li><Link to="/jardin">Inicio</Link></li> {/* Ruta principal */}
          <li><Link to="/Jardin/matriculaJardin">Matrícula</Link></li> {/* Ruta de matrícula */}
          <li><Link to ="/Jardin/pagosJardin">Pagos</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default HeaderJardin;
