// src/pages/Postulacion.js
import React from 'react';

const Admision = () => {
  return (
    <div>
      <div className="image-overlay-container">
        <h1 className="overlay-title">Proceso de admisión 2025</h1>
      </div>

      <div className="container-pages">
      {/* Columna Izquierda */}
      <div className="container-pages-left">
        <h2>Postulaciones</h2>
      </div>

      {/* Columna Derecha */}
      <div className="container-pages-right">
        <div className="container-pages-description">
          <h2>Convocatorias y Períodos de Postulación 2025</h2>
          <h3>Instrucciones generales</h3>
          <p>
            1. Colegio Trupam inicia su proceso de admisión 2025 a través de una convocatoria abierta para todas las familias interesadas en
            participar y adherir a su Proyecto Educativo.
          <br></br>
            2. Los padres y apoderados deben revisar, conocer y aceptar el Proyecto Educativo, el Reglamento Interno y de Convivencia Escolar del
Colegio Trupam, disponibles en la página Web del establecimiento ( www.trupam.cl ). Además, se obligan a respetar los valores y
principios Institucionales junto con cumplir el reglamento, protocolos y normas internas vigentes.
          <br></br>
          3. Se debe completar el formulario de postulación
          <br></br>
          4. Una vez revisados los antecedentes, se citará a una breve entrevista y evaluación
          </p>
          <a
            href="https://forms.gle/yStmxjEwtKVxwyYQ6"
            target="_blank"
            rel="noopener noreferrer"
            className="container-pages-button"
          >
            Formulario de Postulación
          </a>
        </div>
        <div className="container-pages-details">
          <h4>Aranceles y horarios 2025</h4>
          <p>
            Nuestro colegio funciona con jornada completa, con cursos desde PreKinder a 7° básico. 
            El valor indicado corresponde a pagos mensuales de marzo a diciembre.

          </p>
          <ul>
            <li><strong>Playgroup:</strong> Jornada matinal de 08:15 a 12:30 hrs, $130.000 | Jornada tarde de 13:45 a 18:00 hrs, $130.000 | Jornada completa de 08:00 a 18:00 hrs, $250.000</li>
            <li><strong>PreKinder y Kinder:</strong> 08:00 a 12:45, $150.000</li>
            <li><strong>1° a 7° básico:</strong> Lunes a jueves desde 08:00 hasta 15:00 hrs. Viernes desde 08:00 hasta 13:00 hrs, $172.000</li>
       
          </ul>
        </div>
      </div>
    </div>
    </div>
  );
}
export default Admision;
