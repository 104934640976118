import React, { useState } from "react";
import "./Form.css";

function Form() {

  const functionKey = process.env.REACT_APP_BACKEND_URL;
  console.log(functionKey)
  const [formData, setFormData] = useState({
    nombreAlumno: "",
    curso: "",
    tipoCertificado: "Certificado de alumno regular",
    rutApoderado: "",
    formaEntrega: "Retiro en oficina",
    nombreApoderado: "",
    numeroCopias: 1,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Crear un objeto con los datos del formulario
    const formDataToSend = {
      nombreAlumno: formData.nombreAlumno,
      curso: formData.curso,
      tipoCertificado: formData.tipoCertificado,
      rutApoderado: formData.rutApoderado,
      nombreApoderado: formData.nombreApoderado,
      formaEntrega: formData.formaEntrega,
      numeroCopias: formData.numeroCopias,
    };

    try {
      // Enviar la solicitud POST con los datos en formato JSON
      const response = await fetch(functionKey, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",  // Establecer el tipo de contenido como JSON
        },
        body: JSON.stringify(formDataToSend),  // Convertir el objeto JavaScript a JSON
      });

      const responseData = await response.json();

      if (response.ok) {
        alert("Formulario enviado correctamente.");
        setFormData({
          nombreAlumno: "",
          curso: "",
          tipoCertificado: "Certificado de alumno regular",
          rutApoderado: "",
          formaEntrega: "Retiro en oficina",
          nombreApoderado: "",
          numeroCopias: 1
        });
      } else {
        alert(`Error: ${responseData.message}`);
      }
    } catch (error) {
      console.error("Error al enviar el formulario:", error);
      alert("Ocurrió un error al enviar el formulario.");
    }
  };

  return (
    <form className="solicitud-form" onSubmit={handleSubmit}>
      <h2 className="form-title">Solicitud de Certificado</h2>

      <div className="form-row">
        <div className="form-group">
          <label>Nombre del Alumno</label>
          <input
            type="text"
            name="nombreAlumno"
            value={formData.nombreAlumno}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Curso</label>
          <select
            name="curso"
            value={formData.curso}
            onChange={handleChange}
            required
          >
            <option value="Pre-Kinder">Pre-Kinder</option>
            <option value="Kinder">Kinder</option>
            <option value="1° Básico">1° Básico</option>
            <option value="2° Básico">2° Básico</option>
            <option value="3° Básico">3° Básico</option>
            <option value="4° Básico">4° Básico</option>
            <option value="5° Básico">5° Básico</option>
            <option value="6° Básico">6° Básico</option>
          </select>
        </div>
      </div>

      <div className="form-group">
        <label>Tipo de Certificado</label>
        <select
          name="tipoCertificado"
          value={formData.tipoCertificado}
          onChange={handleChange}
          required
        >
          <option value="Certificado de alumno regular">Certificado de alumno regular</option>
          <option value="Informe de notas">Informe de notas</option>
          <option value="Certificado anual de estudios">Certificado anual de estudios</option>
          <option value="Certificado de matrícula">Certificado de matrícula</option>
          <option value="Informe de personalidad">Informe de personalidad</option>
        </select>
      </div>

      <div className="form-row">
        <div className="form-group">
          <label>RUT del Apoderado</label>
          <input
            type="text"
            name="rutApoderado"
            value={formData.rutApoderado}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Nombre del Apoderado</label>
          <input
            type="text"
            name="nombreApoderado"
            value={formData.nombreApoderado}
            onChange={handleChange}
            required
          />
        </div>
      </div>

      <div className="form-group">
        <label>Forma de Entrega</label>
        <select
          name="formaEntrega"
          value={formData.formaEntrega}
          onChange={handleChange}
          required
        >
          <option value="Retiro en oficina">Retiro en oficina</option>
          <option value="Envío por correo">Envío por correo</option>
        </select>
      </div>

      <div className="form-group">
        <label>Número de Copias</label>
        <input
          type="number"
          name="numeroCopias"
          value={formData.numeroCopias}
          onChange={handleChange}
          min="1"
          required
        />
      </div>

      <button type="submit" className="submit-button">
        Enviar Solicitud
      </button>
    </form>
  );
}

export default Form;
