import React from 'react';
import "./SubPage.css"
const Matricula = () => {
  return (
    <div>
      <div className="image-overlay-container">
        <h1 className="overlay-title">Matrícula</h1>
      </div>

      <div className="container-pages">
      {/* Columna Izquierda */}
      <div className="container-pages-left">
        <h2>Alumnos antiguos</h2>
      </div>

      {/* Columna Derecha */}
      <div className="container-pages-right">
        <div className="container-pages-description">
          <h3>Proceso de matrícula año escolar 2025</h3>
          <p>
            
          </p>
          {/* Botón */}
          <a
            href="https://www.example.com/formulario-postulacion"
            target="_blank"
            rel="noopener noreferrer"
            className="container-pages-button"
          >
            Formulario de Postulación
          </a>
        </div>
        <div className="container-pages-details">
          <h4>Pre-Kínder 2025</h4>
          <p>
            Nuestro colegio mantiene el modelo mixto en este nivel. Desde 3° básico,
            inicia el proyecto diferenciado con cursos paralelos de niños y niñas.
          </p>
          <ul>
            <li><strong>Familias actuales y ex alumnas:</strong> 16 de febrero al 04 de marzo.</li>
            <li><strong>Familias nuevas:</strong> 04 de marzo al 15 de marzo.</li>
          </ul>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Matricula;
