import React from 'react';
import "./SubPageJardin.css"
const MatriculaJardin = () => {
  return (
    <div>
      <div className="image-overlay-container">
        <h1 className="overlay-title">Matrícula</h1>
      </div>

      <div className="container-pages">
      {/* Columna Izquierda */}
      <div className="container-pages-left">
        <h2>Inscripción</h2>
      </div>

      {/* Columna Derecha */}
      <div className="container-pages-right">
        <div className="container-pages-description">
          <h3>Proceso de matrícula</h3>
          <p>
            Para optar a un cupo en Jardín Infantil y Sala Cuna Trupam, 
            solicitamos completar el siguiente formulario.

            Seguido a esto, nos contactaremos para brindar la información pertinente 
            y coordinar una entrevista.
            
          </p>
          {/* Botón */}
          <a
            href="https://forms.gle/ZBp6xAqup26YSicT8"
            target="_blank"
            rel="noopener noreferrer"
            className="container-pages-button"
          >
            Formulario de contacto
          </a>
        </div>
        <div className="container-pages-details">
          <h4>Horarios</h4>
          <p>
           <strong>Horario de funcionamiento:</strong> Horario de funcionamiento: 07:15 a 18:30 hrs
            <br/>
            <strong>Jornada completa: </strong> 08:00 a 18:00 hrs
            <br/>
            <strong>Jornada mañana: </strong> 08:00 a 13:00 hrs
            <br/>
            <strong>Jornada tarde: </strong> 13:00 a 18:00 hrs
          </p>
        </div>
      </div>
    </div>
    </div>
  );
};

export default MatriculaJardin;
