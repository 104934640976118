import {React, useEffect} from "react";
import { Outlet, useLocation } from "react-router-dom"; // Importamos useLocation para obtener la ruta actual
import HeaderJardin from "../../components/Header/HeaderJardin"; // Importamos el Header
import Slider from "../../components/Slider"; // Importamos el Slider
import Footer from "../../components/Footer/Footer";
import "./Jardin.css"; // Importamos los estilos específicos para Colegio
// Importar las imágenes del Slider
import slide1 from '../../assets/images/matricula-jardin.png'
import slide3 from '../../assets/images/slide3.png'


import image1 from '../../assets/images/imagej.jpg'
import image2 from '../../assets/images/imagej2.jpg'
import image3 from '../../assets/images/imaagej3.jpg'
import image4 from '../../assets/images/imagej4.jpg'

function Jardin() {
  const location = useLocation(); // Obtenemos la ubicación actual
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]); // El array vacío indica que el efecto se ejecutará solo una vez después del montaje
  // Aquí determinamos si estamos en la página principal '/colegio'
  const isJardinPage = location.pathname === "/jardin";

  // Array de imágenes para el Slider
  const images = [slide1,slide3];

  return (
    <div className="Jardin-container">
      {/* Solo mostramos el Header y el Slider en '/colegio' */}
      <HeaderJardin />
      {isJardinPage && (
        <>
          <Slider images={images} />
                <div className="divisions-container">
        <h2 className="divisions-title">Sobre nosotros</h2>
        <div className="division-cards">
          <div className="division-card">
            <img src={image1} alt="Early Years School" />
            <div className="card-info">
              <h3>1986</h3>
              <p>Año de inaguración</p>
            </div>
          </div>
          <div className="division-card">
            <img src={image2} alt="Elementary School" />
            <div className="card-info">
              <h3>Comodidad</h3>
              <p>Espacios recreativos</p>
            </div>
          </div>
          <div className="division-card">
            <img src={image3} alt="Middle School" />
            <div className="card-info">
              <h3>Niveles</h3>
              <p>Jardín y Salacuna</p>
            </div>
          </div>
          <div className="division-card">
            <img src={image4} alt="High School" />
            <div className="card-info">
              <h3>Proyectos</h3>
              <p>Remodelación en proceso</p>
            </div>
          </div>
        </div>
      </div>  
        </>
      )}
      {/* El Outlet renderiza las subpáginas como Matrícula, Nosotros, Postulación */}
      <div className="jardin-content">
        <Outlet /> {/* Aquí se cargan los componentes de las subpáginas */}
      </div>
      <Footer />
    </div>
  );
}

export default Jardin;
