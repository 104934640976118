import React from 'react';
import "./SubPageJardin.css"
const Pagos = () => {
  return (
    <div>
      <div className="image-overlay-container">
        <h1 className="overlay-title">Pagos online</h1>
      </div>

      <div className="container-pages">
      {/* Columna Izquierda */}
      <div className="container-pages-left">
        <h2>Webpay o transferencia bancaria</h2>
      </div>

      {/* Columna Derecha */}
      <div className="container-pages-right">
        <div className="container-pages-description">
          <h3>Webpay</h3>
          <p>
          1. Ingresar al link de pago
        <br />
          2. Seleccionar "generales" y "continuar"
        <br />
          3. Completar datos y monto a pagar 
        <br />
          4. Seleccionar método de pago
        <br />
          5. Enviar comprobante a pagos@trupam.cl
          </p>
          {/* Botón */}
          <a
          href="https://www.webpay.cl/company/33891"
          target="_blank"
          rel="noopener noreferrer"
          className="container-pages-button"
          >
            Webpay
          </a>
        </div>
        <div className="container-pages-details">
          <h4>Transferencia bancaria</h4>
          <p>

          Banco: BCI 
          <br/>
          Cuenta corriente: 76552691
          <br/>
          Nombre: Jardin Infantil y Sala Cuna Trupam Limitada
          <br/>
          Rut: 76.239.442-1
          <br/>
          Correo: pagos@trupam.cl
          </p>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Pagos;
